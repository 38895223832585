<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @hidden="hideModal()"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon :icon="taskTypeIcon"></b-icon>
        <span>Crea {{ taskTypeLabel }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="
          cancel();
          cancelEventCreation();
        "
      >
        &times;
      </button>
    </template>
    <!-- <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn
          @click="
            cancel();
            cancelEventCreation();
          "
          size="sm"
          variant="outline-secondary"
          >Annulla</b-btn
        >
        <b-button
          @click="handleSubmit(createEvent)"
          type="button"
          :disabled="invalid"
          variant="outline-lisaweb"
          size="sm"
        >
          Salva
        </b-button>
      </b-form-group>
    </template> -->
    <validation-observer ref="observer2" v-slot="{ handleSubmit, invalid }">
      <b-card>
        <b-card-text>
          <base-input
            vid="title"
            :name="beFormCreate.title.label"
            :label="beFormCreate.title.label"
            v-model="inputVal.title"
            :placeholder="beFormCreate.title.label"
            :rules="getRules('title', 'task')"
            maxlength="25"
          />

          <base-textarea
            vid="description"
            :name="beFormCreate.description.label"
            :label="beFormCreate.description.label"
            v-model="inputVal.description"
            :rules="getRules('description', 'task')"
            :placeholder="beFormCreate.description.label"
          />

          <b-button-group
            v-b-toggle:collapse-2
            class="my-2 filter-button-group"
            v-if="fromCalendar"
          >
            <span class="mr-2">Appuntamento</span>
            <span class="when-open">
              <b-icon icon="chevron-up"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="chevron-down"></b-icon>
            </span>
          </b-button-group>

          <b-collapse class="pb-2" id="collapse-2" :visible="fromCalendar">
            <base-checkbox
              vid="allDay"
              name="allDay"
              label="Tutto il giorno"
              groupLabel=""
              v-model="inputVal.allDay"
              v-if="inputVal.fromDate === inputVal.toDate"
            />

            <div class="row">
              <div class="col-md-6">
                <base-timepicker
                  v-if="!inputVal.allDay"
                  vid="startTime"
                  name="startTime"
                  label="Dalle ore"
                  v-model="inputVal.startTime"
                  :rules="{ required: taskType == 0 }"
                />
              </div>
              <div class="col-md-6">
                <base-timepicker
                  v-if="!inputVal.allDay"
                  vid="endTime"
                  name="endTime"
                  label="Alle ore"
                  v-model="inputVal.endTime"
                  :rules="{ required: taskType == 0 }"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <base-datepicker
                  vid="started_at"
                  name="started_at"
                  :label="beFormCreate.start.label"
                  v-model="inputVal.fromDate"
                  :rules="{ required: taskType == 0 }"
                />
                <!-- :rules="getRules('started_at', 'task')" -->
              </div>
              <div class="col-md-6">
                <base-datepicker
                  vid="ended_at"
                  name="ended_at"
                  :label="beFormCreate.end.label"
                  v-model="inputVal.toDate"
                  :rules="{ required: taskType == 0 }"
                />
                <!-- :rules="getRules('ended_at', 'task')" -->
              </div>
            </div>
          </b-collapse>

          <base-datepicker
            vid="expired_at"
            :name="beFormCreate.expired_at.label"
            :label="beFormCreate.expired_at.label"
            v-model="inputVal.expired_at"
            :rules="getRules('expired_at', 'task')"
          />

          <base-radio
            vid="is_public"
            :name="beFormCreate.is_public.label"
            :label="beFormCreate.is_public.label"
            v-model="is_public"
            :options="beFormCreate.is_public.options"
            :rules="getRules('is_public', 'task')"
            @select="onChangeIsPublic"
          />

          <base-select
            v-if="is_public === 'N'"
            vid="users"
            :name="beFormCreate.users.label"
            :label="beFormCreate.users.label"
            v-model="inputVal.users"
            :options="usersOptions"
            :multiple="true"
            :taggable="false"
            :closeOnSelect="false"
            :rules="getRules('users', 'task')"
          />
          <base-select
            v-if="taskType === 1"
            vid="status_task"
            :name="beFormCreate.status_task.label"
            :label="beFormCreate.status_task.label"
            v-model="inputVal.status_task"
            :options="beFormCreate.status_task.options"
            :rules="{ required: true }"
          />
          <!-- Se è una nota attiva la possibilità di creare un appuntamento -->
          <label v-if="taskType === 2">Aggiungi Appuntamento</label>
          <base-checkbox
            v-if="taskType === 2"
            vid="create_appointment"
            name="create_appointment"
            label=""
            v-model="create_appointment"
            @change="checkAppointment"
          />
          <div style="visibility: hidden">
            <base-label
              vid="task_type"
              name="task_type"
              :value="inputVal.task_type"
            />
          </div>
        </b-card-text>
        <b-button
          @click="handleSubmit(createEvent)"
          type="button"
          :disabled="invalid"
          variant="outline-lisaweb"
          size="sm"
          class="float-right"
        >
          Salva
        </b-button>
      </b-card>
    </validation-observer>
  </b-modal>
</template>
<script>
import BaseTimepicker from "@/components/form/BaseTimepicker";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseRadio from "@/components/form/BaseRadio";
import BaseLabel from "@/components/form/BaseLabel";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin],
  data() {
    return {
      usersOptions: null,
      create_appointment: false,
      is_public: "Y",
    };
  },
  props: {
    // taskType: {
    //   type: Number,
    //   default: 0,
    // },
    id: {
      type: String,
      default: "eventCreateModal",
    },
    value: null,
    beRulesCreate: {
      type: Object,
      default() {
        return {};
      },
    },
    beFormCreate: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseInput,
    BaseTextarea,
    BaseCheckbox,
    BaseSelect,
    BaseTimepicker,
    BaseDatepicker,
    BaseRadio,
    BaseLabel,
  },
  computed: {
    fromCalendar() {
      return this.taskType === 0 ? true : false;
    },
    taskType() {
      console.debug("eventCreate taskType(): ", this.inputVal.task_type);
      return this.inputVal.task_type;
    },
    taskTypeIcon() {
      let icon;
      switch (this.taskType) {
        case 0:
          icon = "calendar2-event";
          break;
        case 1:
          icon = "paperclip";
          break;
        case 2:
          icon = "journal-minus";
          break;
        default:
          icon = "question-circle";
      }
      return icon;
    },
    taskTypeLabel() {
      let label;
      switch (this.taskType) {
        case 0:
          label = "Appuntamento";
          break;
        case 1:
          label = "Attività";
          break;
        case 2:
          label = "Nota";
          break;
        default:
          label = "sconosciuto";
      }
      return label;
    },
    inputVal: {
      get() {
        // return {...this.value, is_public: 'Y'};
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    hideModal() {
      console.log("hideModal");
      this.$bvModal.hide(this.id);
      this.is_public = "Y";
      this.inputVal.is_public = null;
    },
    onChangeIsPublic(checkedOption) {
      if (checkedOption === "Y") {
        this.inputVal.users = [];
      }
    },
    cancelEventCreation() {
      this.$emit("cancel");
    },
    checkAppointment() {
      console.log("Change", this.create_appointment);
      this.$emit("addAppointment", this.create_appointment);
    },
    createEvent() {
      this.inputVal.is_public = this.is_public;
      this.$emit("create");
    },
    ...mapGetters("auth", ["user"]),
  },
  beforeMount() {
    this.beRules["task"] = this.beRulesCreate;
    const owner = this.user();
    this.usersOptions = this.beFormCreate.users.options.filter(
      (user) => user.value !== owner.id
    );
  },
};
</script>
<style lang="scss" scoped>
:deep(.collapse > span:last-of-type > div.form-group) {
  margin-bottom: 0;
}
:deep(.collapse + span) {
  display: block;
  margin-top: 1rem;
}
:deep(.collapse .form-group) {
  margin-bottom: 1rem;
}
</style>
